<template>
  <el-dialog
    :close-on-click-modal="false"
    title="用户提现"
    :visible.sync="DialogVisible"
    width="30%"
    @close="DialogClosed"
  >
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRef"
      label-width="110px"
    >
      <el-form-item label="交易流水号：" prop="transactionNo">
        <el-input
          v-model.trim="addForm.transactionNo"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="处理时间：" prop="payTime">
        <el-date-picker
          v-model="addForm.payTime"
          type="datetime"
          placeholder="选择处理时间"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="资金去向：" prop="payTo" style="text-align: left">
        <el-radio-group v-model="addForm.payTo">
          <el-radio label="支付宝账户"></el-radio>
          <el-radio label="微信账户"></el-radio>
          <el-radio label="银行账户"></el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmSet">完成处理</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    addForm: {
      type: Object,
    },
    addFormRules: {
      type: Object,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    DialogVisible: {
      get () {
        return this.visible
      },
      set () {
      }
    }
  },
  methods: {
    confirmSet () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.$emit('confirmSet')
        }
      })

    },
    DialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.$emit('closeSetDialog')
    },
  }
}
</script>