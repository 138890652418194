<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>工单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="userID">
            <div style="display: inline-block" class="divSpan">用户ID：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.userID"
                clearable
                @clear="getList"
                style="width: 200px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="workType">
            <div style="display: inline-block" class="divSpan">工单类型：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.workType"
                :options="workStatus"
                :optionKey="workStatuskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="transcationType">
            <div style="display: inline-block" class="divSpan">处理状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.transcationType"
                :options="transcationStatus"
                :optionKey="workStatuskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="workNo">
            <div style="display: inline-block" class="divSpan">工单号：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.workNo"
                clearable
                @clear="getList"
                style="width: 180px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="payTime">
            <div style="display: inline-block" class="divSpan">申请时间：</div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.payTime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dateSerach"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
          <el-button type="info" @click="exportS">导出</el-button>
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="workList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="工单类型"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="sheetNo"
            label="工单号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="tradeNo"
            label="订单编号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="transactionNo"
            label="交易流水号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="金额(元)"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="createTime"
            label="申请时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="checkTime"
            label="处理时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="工单状态"
            min-width="150px"
          ></el-table-column>

          <el-table-column
            prop="note"
            label="备注"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="交易摘要" min-width="100px" prop="summary">
          </el-table-column>

          <el-table-column label="操作" min-width="100px" fixed="right">
            <template slot-scope="scope">
              <!-- <el-button
                size="mini"
                type="primary"
                @click="viewDetail(scope.row)"
                v-show="scope.row.status === '已处理'"
                >查看</el-button
              > -->
              <el-button
                size="mini"
                type="warning"
                @click="pendButton(scope.row)"
                v-show="scope.row.status !== '已处理'"
                >处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 导出 -->
    <div v-if="exportDialogVisible">
      <ExportDialog
        :visible.sync="exportDialogVisible"
        title="导出工单"
        @confirmExport="confirmExport"
        @closeExportDialog="closeExportDialog"
      >
      </ExportDialog>
    </div>

    <!-- 用户提现 -->
    <div v-if="pendDialogVisible">
      <PendDialog
        :visible.sync="pendDialogVisible"
        :addForm="addForm"
        :addFormRules="addFormRules"
        @confirmSet="confirmSet"
        @closeSetDialog="pendPlatDialogClosed"
      >
      </PendDialog>
    </div>

    <!-- 用户退款 -->
    <div v-if="refundDialogVisible">
      <RefundDialog
        :visible.sync="refundDialogVisible"
        :addForm="refundForm"
        :addFormRules="refundFormRules"
        @confirmSet="refundconfirmSet"
        @closeSetDialog="refundPlatDialogClosed"
      >
      </RefundDialog>
    </div>
  </div>
</template>
<script>
import ExportDialog from '@/components/myself/exportDialog.vue'
import { exportRecord } from '@/components/myself/DownloadFile'
import ELselect from '@/components/myself/el_select'
import { getWorksheetPage, withdraw, refundOrder } from '@/api/cloudmarket/finance'
import PendDialog from './pendDialog.vue'
import RefundDialog from './refundDialog.vue'

export default {
  components: { ExportDialog, ELselect, PendDialog, RefundDialog },

  data () {
    return {
      queryinfo: {
        pageNum: 1,
        pageSize: 20,
        payTime: '',
        workType: '',
        transcationType: '',
        userID: '',
        workNo: '',
      },
      total: 0,

      workStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '提现'
        },
        {
          value: 1,
          label: '退款'
        }
      ],
      workStatuskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },
      transcationStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '已处理'
        },
        {
          value: 0,
          label: '未处理'
        },
      ],
      workList: [],
      ID: '',
      exportDialogVisible: false,
      pendDialogVisible: false,
      addForm: {
        transactionNo: '',
        payTo: '支付宝账户',
        payTime: ''
      },
      addFormRules: {
        transactionNo: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        payTo: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        payTime: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },

      refundDialogVisible: false,
      refundForm: {
        transactionNo: '',
        tradeNumber: '',
        note: '',
      },
      refundFormRules: {
        tradeNumber: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : ''
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : ''
      let querise = { startTime: time1, endTime: time2, userId: this.queryinfo.userID, type: this.queryinfo.workType, status: this.queryinfo.transcationType, sheetNo: this.queryinfo.workNo }
      const res = await getWorksheetPage(this.queryinfo.pageNum, this.queryinfo.pageSize, querise)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.workList = res.data.data.list
      this.total = res.data.data.total
    },
    dateSerach () {
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    confirmExport (para) {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : ''
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : ''
      let isAll = 0
      if (para !== 1) {
        isAll = 1
      }
      let quiese = { isAll: isAll, pageNum: this.queryinfo.pageNum, pageSize: this.queryinfo.pageSize, startTime: time1, endTime: time2, userId: this.queryinfo.userID, type: this.queryinfo.workType, status: this.queryinfo.transcationType, sheetNo: this.queryinfo.workNo }

      exportRecord(quiese, '工单明细', 3)
      this.closeExportDialog()

    },
    exportS () {
      this.exportDialogVisible = true
    },
    closeExportDialog () {
      this.exportDialogVisible = false
    },

    pendButton (para) {
      if (para.type === '提现') {
        this.pendDialogVisible = true
        this.ID = para.sheetNo
      } else {
        this.refundDialogVisible = true
        this.refundForm.tradeNumber = para.sheetNo
      }
    },
    pendPlatDialogClosed () {
      this.pendDialogVisible = false
      this.addForm.transactionNo = ''
      this.addForm.payTime = ''
      this.addForm.payTo = '支付宝账户'
    },
    async confirmSet () {
      let quiese = { payTime: this.addForm.payTime, payTo: this.addForm.payTo, id: this.ID, transactionNo: this.addForm.transactionNo }
      const res = await withdraw(quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.pendDialogVisible = false
      this.getList()
    },
    // 退款
    async refundconfirmSet () {

      const res = await refundOrder(this.refundForm.tradeNumber, this.refundForm.transactionNo, this.refundForm.note)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.refundDialogVisible = false
      this.getList()
    },
    refundPlatDialogClosed () {
      this.refundDialogVisible = false
      this.refundForm.transactionNo = ''
      this.refundForm.tradeNumber = ''
      this.refundForm.note = ''
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-form-item__content {
  margin-right: 30px;
}
</style>