import { DownloadFile } from '@/api/cloudmarket/apporoval'
import { exportAccountDetail, exportTransactionDetail, exportWorksheet } from '@/api/cloudmarket/finance'
import { Loading } from 'element-ui';
import { exportLink, exportMarketIngOrder } from '@/api/ActionSale.js'
import ElementUI from 'element-ui'

export const DownFiles = async (itemId, versionId, itemType, packInfo) => {
  const res = await DownloadFile({ itemId: itemId, versionId: versionId, itemType: itemType })
  if (res.status !== 200) return ElementUI.Message.error('下载失败')
  // console.log('000000000000000', res, { itemId: itemId, versionId: versionId, itemType: itemType })
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)

  let loadingInstance = Loading.service({ lock: true, text: '正在下载中，请稍等', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });

  let extension = ".mp4,.wmv,.avi,.rm,.rmvb,.mp3,.wma".includes(packInfo.type.toLowerCase())
  if (extension) {
    // 文件流（需先转换成blob类型）
    const blob = new Blob([res.data.data])
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = decodeURIComponent(packInfo.name)
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)

  } else {
    // url格式
    let downloadElement = document.createElement('a')
    let href = res.data.data
    downloadElement.href = href
    downloadElement.download = 'download'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
  }

  loadingInstance.close()

}


export const exportRecord = async (quiese, name, type) => {
  let res = {}
  if (type === 1) {
    res = await exportAccountDetail(quiese)
  } else if (type === 2) {
    res = await exportTransactionDetail(quiese)
  } else if (type === 3) {
    res = await exportWorksheet(quiese)
  } else if (type === 4) {
    res = await exportLink(quiese)
  } else if (type === 5) {
    res = await exportMarketIngOrder(quiese)
  }

  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  let loadingInstance = Loading.service({ lock: true, text: '正在导出中，请稍等', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
  const blob = new Blob([res.data], {
    type: 'application/vnd.ms-excel'
  })
  let downloadElement = document.createElement('a')
  let href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  downloadElement.download = decodeURIComponent(name)
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
  loadingInstance.close()
}