import axios from '@/libs/api.request'

export const createActivity = (quiese) => {
  let uri = '/marketing/createActivity'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const deleteActivity = (id) => {
  let uri = '/marketing/deleteActivity/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const enableActivity = (id) => {
  let uri = '/marketing/enableActivity/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getActivityList = (pageNum, pageSize, quiese) => {
  let uri = '/marketing/getActivityList/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const getMarketingById = (id) => {
  let uri = '/marketing/getMarketingById/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const getSellerID = () => {
  let uri = '/marketing/getSellerList'
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const getSellerList = (pageNum, pageSize, name) => {
  let uri = '/marketing/getSellerPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get',
    params: { name: name }
  })
}

export const getLinkList = (sellerId) => {
  let uri = '/marketing/getLinkList/' + sellerId
  return axios.request({
    url: uri,
    method: 'get',
  })
}

export const getSellerOrderList = (pageNum, pageSize, quiese) => {
  let uri = '/marketing/getSellerOrderList/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}



// 导出
export const exportLink = (quiese) => {
  let uri = '/marketing/exportLink'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,
    responseType: 'blob'
  })
}

export const exportMarketIngOrder = (quiese) => {
  let uri = '/marketing/exportMarketIngOrder'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,
    responseType: 'blob'
  })
}