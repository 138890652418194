import axios from '@/libs/api.request'

// 设置分成比例
export const setProportion = (divide) => {
  let uri = '/finance/proportion/' + divide
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 查看分成比例
export const getProportion = () => {
  let uri = '/finance/proportion/show'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 订单退款
export const refundOrder = (sheetNo, transactionNo, note) => {
  let uri = '/finance/refund'
  return axios.request({
    url: uri,
    method: 'get',
    params: { sheetNo: sheetNo, transactionNo: transactionNo, note: note }
  })
}

export const createRefundWorkSheet = (tradeNo) => {
  let uri = '/finance/createRefundWorkSheet'
  return axios.request({
    url: uri,
    method: 'get',
    params: { tradeNo: tradeNo }
  })
}

// 用户提现（完成待处理状态）
export const withdraw = (quiese) => {
  let uri = '/finance/withdraw'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}



// 交易明细
export const getTransactionDetailPage = (pageNum, pageSize, quiese) => {
  let uri = '/finance/getTransactionDetailPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}
export const getTransactionDetailById = (id) => {
  let uri = '/finance/getTransactionDetailById/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 动账记录
export const getAccountDetailed = (pageNum, pageSize, quiese) => {
  let uri = '/finance/getAccountDetail/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}
export const getAccountDetailById = (id) => {
  let uri = '/finance/getAccountDetailById/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 工单列表
export const getWorksheetPage = (pageNum, pageSize, quiese) => {
  let uri = '/finance/getWorksheetPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


// 导出
export const exportAccountDetail = (quiese) => {
  let uri = '/finance/exportAccountDetail'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,
    responseType: 'blob'
  })
}
export const exportTransactionDetail = (quiese) => {
  let uri = '/finance/exportTransactionDetail'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,
    responseType: 'blob'
  })
}
export const exportWorksheet = (quiese) => {
  let uri = '/finance/exportWorksheet'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese,
    responseType: 'blob'
  })
}