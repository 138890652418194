<template>
  <el-dialog
    :close-on-click-modal="false"
    title="订单退款"
    :visible.sync="DialogVisible"
    width="30%"
    @close="DialogClosed"
  >
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRef"
      label-width="110px"
    >
      <el-form-item label="工单号：" prop="tradeNumber">
        <el-input
          v-model.trim="addForm.tradeNumber"
          placeholder="请输入"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="交易流水号：" prop="transactionNo">
        <el-input
          v-model.trim="addForm.transactionNo"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="note">
        <el-input
          v-model.trim="addForm.note"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmSet">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    addForm: {
      type: Object,
    },
    addFormRules: {
      type: Object,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    DialogVisible: {
      get () {
        return this.visible
      },
      set () {
      }
    }
  },
  methods: {
    confirmSet () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.$emit('confirmSet')
        }
      })

    },
    DialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.$emit('closeSetDialog')
    },
  }
}
</script>