import axios from '@/libs/api.request'

/* 审批流程节点*/
export const DeleteFlowNode = (id) => {
  let uri = '/flowNode/delete/' + id
  return axios.request({
    url: uri,
    method: 'post'
  })
}

// 已保存的流程
export const getFlowNodeType = (flowType) => {
  let uri = '/flowNode/getFlowNode/' + flowType
  return axios.request({
    url: uri,
    method: 'get'
  })
}


// export const insertNode = (quiese) => {
//   let uri = '/flowNode/insert'
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

export const insertFlowNode = (flowNodes) => {
  let uri = '/flowNode/insertFlowNode'
  return axios.request({
    url: uri,
    method: 'post',
    data: flowNodes
  })
}

export const getFlowNodePage = (pageNum, pageSize, quiese) => {
  let uri = '/flowNode/name/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

// export const updateFlowNode = (quiese) => {
//   let uri = '/flowNode/update/' + quiese.id
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

// export const getFlowNode = (id) => {
//   let uri = '/flowNode/' + id
//   return axios.request({
//     url: uri,
//     method: 'get'
//   })
// }


/***************************************************** */

/* 流程类型管理*/
export const insertFlowType = (quiese) => {
  let uri = '/flowType/'
  return axios.request({
    url: uri,
    method: 'put',
    data: quiese
  })
}
export const getFlowTypeAll = () => {
  let uri = '/flowType/getTypeAll'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getFlowTypePage = (pageNum, pageSize, quiese) => {
  let uri = '/flowType/name/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const DeleteFlowType = (id) => {
  let uri = '/flowType/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

export const getFlowType = (type) => {
  let uri = '/flowType/' + type
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const updateFlowType = (quiese) => {
  let uri = '/flowType/' + quiese.type
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


/***************************************************** */

// /* 流程步骤管理*/
// export const insertFlowProcess = (quiese) => {
//   let uri = '/flowProcess/'
//   return axios.request({
//     url: uri,
//     method: 'put',
//     data: quiese
//   })
// }
// export const getFlowProcessPage = (pageNum, pageSize, quiese) => {
//   let uri = '/flowProcess/name/' + pageNum + '/' + pageSize
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }
// export const getFlowProcess = (id) => {
//   let uri = '/flowProcess/' + id
//   return axios.request({
//     url: uri,
//     method: 'get'
//   })
// }

// export const updateFlowProcess = (quiese) => {
//   let uri = '/flowProcess/' + quiese.id
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

// export const DeleteFlowProcess = (id) => {
//   let uri = '/flowProcess/' + id
//   return axios.request({
//     url: uri,
//     method: 'delete'
//   })
// }


/***************************************************** */

// /* 审批工单管理*/
// export const insertAuditOrder = (quiese) => {
//   let uri = '/auditOrder/'
//   return axios.request({
//     url: uri,
//     method: 'put',
//     data: quiese
//   })
// }
// export const getAuditOrderPage = (pageNum, pageSize, quiese) => {
//   let uri = '/auditOrder/name/' + pageNum + '/' + pageSize
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

// export const updateAuditOrder = (quiese) => {
//   let uri = '/auditOrder/' + quiese.id
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

// export const DeleteAuditOrder = (id) => {
//   let uri = '/auditOrder/' + id
//   return axios.request({
//     url: uri,
//     method: 'delete'
//   })
// }

/***************************************************** */

/* 审批记录管理*/
export const insertAuditRecord = (quiese) => {
  let uri = '/auditRecord/'
  return axios.request({
    url: uri,
    method: 'put',
    data: quiese
  })
}

// export const getAuditRecordPage = (pageNum, pageSize, quiese) => {
//   let uri = '/auditRecord/name/' + pageNum + '/' + pageSize
//   return axios.request({
//     url: uri,
//     method: 'post',
//     data: quiese
//   })
// }

export const getAuditRecordPage = (pageNum, pageSize, quiese) => {
  let uri = '/auditRecord/queryRecordPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const updateAuditRecord = (quiese) => {
  let uri = '/auditRecord/' + quiese.id
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const DeleteAuditRecord = (id) => {
  let uri = '/auditRecord/' + id
  return axios.request({
    url: uri,
    method: 'delete'
  })
}

/***************************************************** */

/* 对象资源审批管理接口*/
export const getAuditObjectBase = (id) => {
  let uri = '/auditObjectBase/getAuditObject/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getAuditObjectBasePage = (pageNum, pageSize, quiese) => {
  let uri = '/auditObjectBase/queryAuditObjectPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const getAudits = (approveDao) => {
  let uri = '/auditObjectBase/approve'
  return axios.request({
    url: uri,
    method: 'post',
    data: approveDao
  })
}


export const DownloadFile = (quiese) => {
  let uri = '/auditObjectBase/objectDownload'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}



export const queryProductDetail = (id) => {
  let uri = '/auditObjectBase/queryProductDetail/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: id
  })
}
