<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="DialogVisible"
    width="30%"
    @close="DialogClosed"
  >
    <el-radio-group v-model="radio" @change="radioGroups">
      <el-radio :label="1">全部</el-radio>
      <el-radio :label="2">当前页</el-radio>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmExport">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      radio: 1
    }
  },
  computed: {
    DialogVisible: {
      get () {
        return this.visible
      },
      set () {
      }
    }
  },
  methods: {
    confirmExport () {
      this.$emit('confirmExport', this.radio)
    },
    radioGroups (para) {
      this.radio = para
    },
    DialogClosed () {
      this.$emit('closeExportDialog')
    },
  }
}
</script>